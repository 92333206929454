import type { FC, PropsWithChildren } from "react";
import { useSidebarContext } from "../../context/SidebarContext";
import classNames from "classnames";
import { Footer } from "../organisms/Footer"

export interface PageContentProps {
  isFooter?: boolean;
}

export const PageContent: FC<PropsWithChildren<PageContentProps>> = function ({
  children, isFooter =true
}) {
  const { isOpenOnSmallScreens: isSidebarOpen } = useSidebarContext();

  return (
    <main
      className={classNames(
        "w-full h-full bg-gray-50 dark:bg-gray-900",
        isSidebarOpen ? "lg:ml-16 lg:w-[calc(100%-theme(space.16))]" : "lg:ml-64 lg:w-[calc(100%-theme(space.64))]",
      )}
    >
      <div className="px-8 pt-12 bg-gray-50 dark:bg-gray-900">
      {children}
      </div>
      {isFooter && (
        <div className={"fixed bottom-0 w-full mt-4"}>
          <Footer />
        </div>
      )}
    </main>
  );
};