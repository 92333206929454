/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip } from "flowbite-react";
import type { FC } from "react";
import {
  HiAdjustments
} from "react-icons/hi";
import { LanguageDropdown } from "../LanguageDropdown";
import { useTranslation } from 'react-i18next';


export const BottomMenu: FC = function () {
  const { t } = useTranslation('navigation');

  return (
    <div className="flex items-center justify-center gap-x-5">
      <div>
        <Tooltip content={t('navigation:sidebar.settings')}>
          <a
            href="/profile"
            className="inline-flex cursor-pointer justify-center rounded p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <span className="sr-only">{t('navigation:sidebar.settings')}</span>
            <HiAdjustments className="text-2xl text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white" />
          </a>
        </Tooltip>
      </div>
      <div>
        <LanguageDropdown />
      </div>
    </div>
  );
};