import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom"
import { Flowbite } from 'flowbite-react';
import { PrimaryTheme } from './theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import "./i18n";
import { UserProvider } from './context/UserContext';
import { WorkspaceProvider } from './context/WorkspaceContext';
import { CacheProvider } from './context/CacheContext';
import { DarkModeProvider } from './context/DarkModeContext';

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Flowbite theme={{ theme: PrimaryTheme }}>
      <BrowserRouter>
        <DarkModeProvider>
          <UserProvider>
            <WorkspaceProvider>
              <CacheProvider>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </CacheProvider>
            </WorkspaceProvider>
          </UserProvider>
        </DarkModeProvider>
      </BrowserRouter>
    </Flowbite>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
