import { type FC } from "react";
import { Label, Button, Tooltip } from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";

export interface FormFieldProp {
  id: string;
  label?: string | undefined;
  tooltip?: string | undefined;
  input: React.ReactNode;
  hidden?: boolean | false;
  isToggle?: boolean | false;
  grid_style?: string | undefined;
}

export interface StandardFormProp {
  buttonConfirmColor?: string;
  buttonConfirmText?: string;
  buttonConfirmPrependIcon?: React.ReactNode;
  buttonCloseText?: string;
  buttonClosePrependIcon?: React.ReactNode;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  showHeader?: boolean | false;
  header?: React.ReactNode;
  toggle?: FormFieldProp;
  fields: Array<FormFieldProp>;
  grid_style?: string;
}

export const StandardForm: FC<StandardFormProp> = (props) => {

  function handleNestedField(data: any, fieldId: any, fieldData: any) {
    const nestedFields = fieldId.split('.');
    // If one level, we save the data as is
    if (nestedFields.length === 1) {
      return setDataField(data, fieldId, fieldData);
    }
    // Extract child data element from the data field if it exists
    var childData = {}
    if (data.hasOwnProperty(nestedFields[0])) {
      childData = data[nestedFields[0]];
    }
    // Enrich child data
    childData = setDataField(childData,  nestedFields.slice(1).join("."), fieldData);
   
    return { ...data, [nestedFields[0]]: childData };
  }

  function setDataField(data: any, fieldId: any, fieldData: any) {
    return { ...data, [fieldId]: fieldData };
  }

  function handleSubmit(event: any) {
    // Prevent default behavior
    event.preventDefault();

    const formData = new FormData(event.target);
    var data = {};

    props.fields.forEach((field) => {
      const fieldId = field.id;
      let fieldData: any = formData.getAll(field.id);
      if (field.isToggle) {
        fieldData = fieldData.length === 1 && fieldData[0] === "on";
      } else if (fieldData.length === 1) {
        fieldData = fieldData[0];
      }

      data = handleNestedField(data, fieldId, fieldData);
      
    });

    props.onConfirm(data);
  }

  const dialogContent = (
    <div>
      {props.showHeader ? <div className="mb-4">{props.header}</div> : null}
      <form onSubmit={handleSubmit}>
        <div className={props.grid_style ? props.grid_style : "grid grid-cols-2 gap-4"}>
          {props.toggle ? <div className="col-span-full">
            <div key={props.toggle.id}>
              {props.toggle.input}
            </div>
          </div> : null }
          {props.fields.map((field) => {
              if (field.hidden) return null;
              return (
                <div key={field.id} className={field.grid_style ?? ""}>
                  {field.label ?  <div className="mb-2 block flex items-center">
                    <Label htmlFor={field.id} value={field.label} />
                    {field.tooltip ? <Tooltip content={field.tooltip}><HiInformationCircle className="ml-1 h-4 w-4 text-lgb-grey-400" /></Tooltip> : null}
                  </div> : null }
                  {field.input}
                </div>
              )
            })
          }
        </div>
        <div className="pt-6 flex gap-4">
          <Button color={props.buttonConfirmColor}  type="submit">
            {props.buttonConfirmPrependIcon}
            {props.buttonConfirmText}
          </Button>
          <Button color="gray" onClick={() => props.onCancel()}>
            {props.buttonClosePrependIcon}
            {props.buttonCloseText}
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <div className="py-4">
      {dialogContent}
    </div>
  );
};
