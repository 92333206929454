import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { Parameter } from "../models";
import { ParameterType, IQueryFilter } from "../types";
import { useCache } from "../context/CacheContext";

export const useParameters = (workspaceId: string, type: ParameterType) => {

  const { cacheKey } = useCache();

  var filters = Array<IQueryFilter>();
  if (type && type !== ParameterType.All) {
    filters.push({
      field: "type",
      operator: "==",
      value: type,
    });
  }

  return useFirestoreQueryData(
    [Parameter.collectionName, cacheKey, workspaceId , type.toString()],
    Parameter.list(workspaceId, filters),
    {
      subscribe: false,
    }
  );
};


