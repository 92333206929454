/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Modal } from "flowbite-react";
import { type FC } from "react";

export interface DialogProp {
  show: boolean;
  buttonCloseText?: string;
  buttonClosePrependIcon?: React.ReactNode;
  hideButtons?: boolean;
  title: string;
  content?: React.ReactNode;
  /**
   * sm,md,lg,xl,2xl,
   * 3xl,4xl,5xl,6xl,7xl
   * 
   * Defaults to 3xl
   */
  size?: string;
  toggleModal: () => void;
}
/**
 * TODO: 
 * Fullscreen on mobile
 */

export const Dialog: FC<DialogProp> = (props) => {
  
  return (
    <div>
      {(props.show) && 
        <Modal show={props.show} size={props.size || '3xl'} onClose={() => props.toggleModal()}>
        <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">{props.title}</Modal.Header>
        <Modal.Body className="py-4">
          <div className="space-y-6">
            {props.content}
          </div>
        </Modal.Body>
        {(!props.hideButtons) && 
          <Modal.Footer className="border-0 pt-0 gap-4">
            <div className="flex justify-end gap-4">
              <Button color="gray" onClick={() => props.toggleModal()}>
                {props.buttonClosePrependIcon}{props.buttonCloseText ?? 'Close'}
              </Button>
            </div>
          </Modal.Footer>
        }
      </Modal>
      }
    </div>
  );
};

