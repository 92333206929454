import { Suspense } from "react";
import { Route, Routes } from "react-router-dom"
import './App.css';
import { PageLayout } from './components';
import { Dashboard, UserPage, WorkspacePage, ProfilePage, MyWorkspaces, WorkspaceUserPage, ConfigurationPage } from './pages';
import { LoginPage, LogoutPage, SignUpPage, PasswordResetPage, WorkspaceLoader } from './pages/auth';
import { NotFoundPage, ServerErrorPage, MaintenancePage } from './pages/utils';
import { useDarkMode } from "./context/DarkModeContext";

function App() {
  const { isDarkMode } = useDarkMode(); 

  if (isDarkMode) {
    document.getElementById('root')?.classList.add('dark');
  } else {
    document.getElementById('root')?.classList.remove('dark');
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route path="/logout" element={<LogoutPage />}></Route>
        <Route path="/signup" element={<SignUpPage />}></Route>
        <Route path="/load_workspace" element={<WorkspaceLoader />}></Route>
        <Route path="/reset_password" element={<PasswordResetPage />}></Route>
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/administration"  >
          <Route path="users" element={<UserPage />} />
          <Route path="workspaces" element={<WorkspacePage />} />
        </Route>
        <Route path="/reports" element={<PageLayout><div className="px-4 pt-6">Reports</div></PageLayout>} />
        <Route path="/workspace"  >
          <Route path="parameters" element={<ConfigurationPage />} />
          <Route path="users" element={<WorkspaceUserPage />} />
        </Route>
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/my_workspaces" element={<MyWorkspaces />} />
        <Route path="/maintenance" element={<MaintenancePage />} />
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="/500" element={<ServerErrorPage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
