/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, FC, PropsWithRef } from 'react';
import { Button, Card, Label, TextInput } from "flowbite-react";
import { useTranslation } from 'react-i18next';
import { ValidationError } from '../../../types/ValidationError';
import { Brand } from '../../atoms/Brand';

interface PasswordRestFormProps {
    onClickPasswordReset: (email: string ) => any;
    validation: ValidationError;
    isProcessing?: boolean | false;
}

const PasswordResetPage: FC<PropsWithRef<PasswordRestFormProps>> = function ({
    onClickPasswordReset, validation, isProcessing
  }) {
    const [email, setEmail] = useState('');

    const { t } = useTranslation('user');

    function handlPasswordReset(event: any) {
        event.preventDefault();
        return onClickPasswordReset(email);
    }

    return (
        <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
            <div className="my-6 flex items-center gap-x-1 lg:my-0">
                <Brand />
            </div>
            <Card
                horizontal
                imgSrc=""
                imgAlt=""
                className="w-full md:max-w-[600px] [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 md:[&>*]:w-full md:[&>*]:p-16 lg:[&>img]:block"
            >
                <h1 className="mb-3 text-2xl font-bold dark:text-white md:text-3xl">
                    {t("login.reset_password_title")}
                </h1>
                <form onSubmit={handlPasswordReset}>
                    <div className="mb-4 flex flex-col gap-y-3">
                        <Label htmlFor="email">{t("login.email.label")}</Label>
                        <TextInput
                            id="email"
                            name="email"
                            placeholder={t("login.email.placeholder")}
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            color={validation.errorFields && validation.errorFields.includes("email")? "failure" : "grey"}
                        />
                        {validation.isError &&
                            <p className="mt-1 text-sm text-red-600 dark:text-red-500">{validation.errorMessage}</p>
                        }
                    </div>
                    <div className="mb-4 w-full">
                        <Button 
                            type="submit" 
                            className="w-full lg:w-full lg:max-w-full" 
                            isProcessing={isProcessing}
                        >
                            {t("login.reset_password")}
                        </Button>
                    </div>
                </form>
            </Card>
        </div>
    );
};

export default PasswordResetPage;
