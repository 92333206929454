import firebase from "firebase/compat/app";
import { firestore } from "../firebaseConfig";
import { Timestamp, IUser } from "../types";
import { query, doc } from "firebase/firestore";

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;

class User implements IUser {
  id: string;
  displayName: string | null;
  email: string;
  emailVerified: boolean;
  userId: string | null;
  isAdmin: boolean | false;
  createdAt?: Timestamp;


  static collectionName = "users";

  static converter = {
    toFirestore(user: User) {
      return user.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as IUser;
      return new User({ ...data, id: snapshot.id });
    }
  };

  static createId() {
    return firestore
      .collection(User.collectionName)
      .doc().id;
  }

  constructor({
    id,
    displayName,
    email,
    emailVerified,
    userId,
    isAdmin,
    createdAt
  }: IUser) {
    this.id = id;
    this.displayName = displayName || null;
    this.email = email;
    this.emailVerified = emailVerified;
    this.userId = userId || null;
    this.isAdmin = isAdmin || false;
    this.createdAt = createdAt;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<IUser>) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      displayName: this.displayName || "",
      email: this.email,
      emailVerified: !!this.emailVerified,
      userId: this.userId || "",
      isAdmin: this.isAdmin || false,
      createdAt: this.createdAt || null
    };
  }

  static defaultUser = (): IUser => {
    return {
      id: "",
      displayName: "",
      email: "",
      emailVerified: false,
      userId: "",
      isAdmin: false,
      createdAt: undefined
    };
  }

  static get = (email: string) => {
    return doc(
      firestore.collection(User.collectionName)
        .withConverter(User.converter),
      email);
  };

  static list = () => {
    return query(
      firestore.collection(User.collectionName)
        .withConverter(User.converter)
        .where("emailVerified", "==", false)
      );
  };


  static create = async (
    data: Omit<
      IUser,
      "id" | "createdAt"
    >
  ) => {
      const newUser = new User({
        ...data,
        id: data.email,
        createdAt: Timestamp.now()
      });

      await firestore
        .collection(User.collectionName)
        .doc(newUser.id)
        .withConverter(User.converter)
        .set(newUser, { merge: true });

      return newUser;
    
  };
}

export default User
