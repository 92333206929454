/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useEffect, useMemo } from "react";
import { Button, Select } from "flowbite-react";
import { HiOutlinePlus, HiTrash, HiPencilAlt } from "react-icons/hi";
import { PageLayout, DialogForm, ParameterModal } from "../../components";
import { useParameters } from "../../hooks";
import { useWorkspace } from "../../context/WorkspaceContext";
import { useCache } from "../../context/CacheContext";
import { TableSkeleton, StandardTable } from "../../components";
import { ITableRow, IParameter, ParameterType} from "../../types";
import { useTranslation } from "react-i18next";
import { Parameter } from "../../models";

export const ConfigurationPage: FC = function () {
  const [isUpsertModalOpen, openUpsertodal] = useState(false); 
  const [isDeleteModalOpen, openDeleteModal] = useState(false); 
  const [parameterType, setParameterType] = useState(ParameterType.All);
  const [isLoading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [parameterData, setParameterData] = useState(Parameter.default());
  const { activeWorkspace } = useWorkspace();
  const { updateCacheKey } = useCache();
  const query = useParameters(activeWorkspace?.workspaceId ?? "", parameterType);
  const { t } = useTranslation(["common", "configuration"]);

  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query]
  );

  const parameters = useMemo(
    () => query.data ?? [],
    [query]
  );

  const parameterOptions = useMemo(
    () => [
      { value: ParameterType.All, label: t('configuration:parameter_types.all') },
      { value: ParameterType.ExampleType, label: t('configuration:parameter_types.example') },
      { value: ParameterType.ExampleType2, label: t('configuration:parameter_types.example2') }
    ],
    [t]
  );

  useEffect(() => {
    if(!isQueryLoading) {
      setLoading(false);
      setTableRows(parameters.map((parameter) => {
        return {
          id: parameter.id,
          cells: [
            {
              id: "code",
              children: parameter.code,
              showOnSmallScreen: true
            },
            {
              id: "type",
              children: parameterOptions.find((option) => option.value === parameter.type.toString())?.label,
              showOnSmallScreen: true
            },
            {
              id: "description",
              children: parameter.description
            },
          ],
          actions: [
            {
              id: "edit",
              icon: <HiPencilAlt />,
              onActionClick: () => {
                setParameterData(parameter);
                openUpsertodal(true);
              }
            },
            {
                id: "delete",
                icon: <HiTrash />,
                color: "failure",
                onActionClick: () => {
                  setParameterData(parameter);
                  openDeleteModal(true);
                }
            }
          ]
        };
      }))
    }
  }, [parameterOptions, isQueryLoading, parameters]);


  return (
    <PageLayout>
      <div className="relative pb-20">
        <h1 className="flex justify-between items-center align-center nowrap pb-8 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
          {t("configuration:parameters")}
          <div className="flex justify-betwee gap-x-6">
            <Select
              defaultValue={parameterType}
              onChange={(e) => {
                setParameterType(e.target.value as ParameterType);
              }}
              
            >
                {parameterOptions.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </Select>
            <Button
              color="light"
              onClick={() => {
                setParameterData(Parameter.default());
                openUpsertodal(true);
              }}
              type="submit"
            >
              <HiOutlinePlus className="mr-2 h-5 w-5" />
              {t("configuration:new_parameter")}
            </Button>
          </div>
        </h1>
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow text:white">
                {isLoading ? (
                  <TableSkeleton />
                ) : (
                  <StandardTable 
                    header={[
                      {
                        id: "code",
                        children: t("configuration:fields.code"),
                        showOnSmallScreen: true
                      },
                      {
                        id: "type",
                        children: t("configuration:fields.type"),
                        showOnSmallScreen: true
                      },
                      {
                        id: "description",
                        children: t("configuration:fields.description")
                      },
                    ]}
                    hasActions={true}
                    rows={tableRows}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ParameterModal 
        data = {parameterData}
        isShowing = {isUpsertModalOpen} 
        onConfirm = {(data: IParameter) => {
          if(Parameter.isNew(parameterData)) {
            Parameter.create(activeWorkspace?.workspaceId ?? "", data);
          } else {
            Parameter.update(parameterData, data);
          }
          updateCacheKey();
          openUpsertodal(false);
        }}
        onCancel = {() => openUpsertodal(false)}
      />

      <DialogForm
        title={t('configuration:delete_parameter') + " " + parameterData.code +"?"}
        show={isDeleteModalOpen}
        toggleModal={() => openDeleteModal(false)} 
        buttonConfirmColor="failure"
        confirmButton={() => {
            Parameter.delete(parameterData);
            updateCacheKey();
            openDeleteModal(false);
        }}
        buttonConfirmText={t("common:confirm")}
        buttonConfirmPrependIcon={<HiTrash className="mr-2 h-4 w-4" />}
        buttonCloseText={t("common:close")}
      />

    </PageLayout>

  );
};
