/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import classNames from "classnames";
import { Sidebar } from "flowbite-react";
import { useEffect, useState } from "react";
import {
  HiChartSquareBar
} from "react-icons/hi";
import { AdministrationIcon, DashboardIcon, WorkSpaceIcon } from "../../atoms/Icons/Icons";
import { BottomMenu } from "../../molecules/BottomMenu";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useUserSession } from "../../../context/UserContext";


import { useSidebarContext } from "../../../context/SidebarContext";
import isSmallScreen from "../../../helpers/is-small-screen";


export const SidebarMenu : FC = function () {
  const { isOpenOnSmallScreens: isSidebarOpenOnSmallScreens } =
    useSidebarContext();

  const [currentPage, setCurrentPage] = useState("");
  const [isAdminOpen, setAdminOpen] = useState(false);
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const { userSession } = useUserSession();
  const navigate = useNavigate();

  const { t } = useTranslation('navigation');

  useEffect(() => {
    const newPage = window.location.pathname;

    setCurrentPage(newPage);
    setAdminOpen(newPage.includes("/administration/"));
    setSettingsOpen(newPage.includes("/workspace/"));
  }, [setCurrentPage, setAdminOpen, setSettingsOpen]);

  return (
    <div
      className={classNames("lg:!block", {
        hidden: !isSidebarOpenOnSmallScreens,
      })}
    >
      <Sidebar
        style={{paddingTop: `85px`}}
        className="flex fixed top-0 left-0 z-20 flex-col rounded-none flex-shrink-0 pt-16 h-full duration-75 border-r border-gray-200 lg:flex transition-width dark:border-gray-700"
        aria-label="Sidebar with multi-level dropdown example"
        collapsed={isSidebarOpenOnSmallScreens && !isSmallScreen()}
      >
        <div className="flex h-full flex-col justify-between py-2">
          <div>
            <Sidebar.Items>
              <Sidebar.ItemGroup>
                <Sidebar.Item
                  onClick={() => navigate("/")}
                  icon={DashboardIcon}
                  className={
                    "/" === currentPage ? "bg-gray-100 dark:bg-gray-700" : ""
                  }
                >
                  {t('sidebar.dashboard')}
                </Sidebar.Item>
                {userSession?.user.isAdmin ? <Sidebar.Collapse
                  icon={AdministrationIcon}
                  label={t("sidebar.administration")}
                  open={isAdminOpen}
                >
                  <Sidebar.Item
                    onClick={() => navigate("/administration/users")}
                    className={
                      "/administration/users" === currentPage
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    {t('sidebar.users')}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() => navigate("/administration/workspaces")}
                    className={
                      "/administration/workspaces" === currentPage
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    {t('sidebar.workspaces')}
                  </Sidebar.Item>
                </Sidebar.Collapse> : null }
                <Sidebar.Item
                  onClick={() => navigate("/reports")}
                  icon={HiChartSquareBar}
                  className={
                    "/reports" === currentPage ? "bg-gray-100 dark:bg-gray-700" : ""
                  }
                >
                  {t('sidebar.reports')}
                </Sidebar.Item>
                <Sidebar.Collapse
                  icon={WorkSpaceIcon}
                  label={t("sidebar.settings")}
                  open={isSettingsOpen}
                >
                  <Sidebar.Item
                    onClick={() => navigate("/workspace/parameters")}
                    className={
                      "/workspace/parameters" === currentPage
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    {t("sidebar.parameters")}
                  </Sidebar.Item>

                  <Sidebar.Item
                    onClick={() => navigate("/workspace/users")}
                    className={
                      "/workspace/users" === currentPage
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    {t('sidebar.users')}
                  </Sidebar.Item>
                </Sidebar.Collapse>
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </div>
          <BottomMenu />
        </div>
      </Sidebar>
    </div>
  );
};