import { Timestamp } from ".";

export enum ParameterType {
    All = "All",
    ExampleType = "ExampleType",
    ExampleType2 = "ExampleType2"
  }

export interface IParameter {
  id: string;
  workspaceId: string;
  code: string;
  type: ParameterType;
  description?: string | null;
  createdAt?: Timestamp;
}
