//https://stackoverflow.com/questions/41030361/how-to-update-react-context-from-inside-a-child-component

import type { PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { IUserSession, ISessionToken, Timestamp } from "../types";


interface UserContextProps {
  userSession: IUserSession | undefined;
  createUserSession: (user: any) => IUserSession | undefined; 
  isAuthenticated: () => boolean;
  revokeUserSession: () => void;
  setUserSession: (userSession: IUserSession | undefined) => void;
  persistUserSession: (session: IUserSession) => void;
}

const UserContext = createContext<UserContextProps>(undefined!);

export function UserProvider({ children }: PropsWithChildren) {
  const [userSession, setUserSession] = useState(
    window.localStorage.getItem("userSession") ?
        JSON.parse(window.localStorage.getItem("userSession") ?? '{}') as IUserSession:
        undefined
    );

  // Save latest state to localStorage
  useEffect(() => {
    if (userSession) {
        window.localStorage.setItem("userSession", JSON.stringify(userSession));
    } else {
        window.localStorage.removeItem("userSession");
    }
  }, [userSession]);
  
  function isAuthenticated(): boolean {
    if (!userSession)
      return false;

    if (userSession.token.expirationTime < (new Date().getTime() + 1000)) {
      return false;
    }

    return true;
  }

  function createUserSession( user: any): IUserSession | undefined {
    if(user) {
      return {
        token: user.stsTokenManager as ISessionToken,
        user: {
          id: user.email,
          email: user.email,
          displayName: user.displayName,
          userId: user.uid,
          emailVerified: user.emailVerified,
          isAdmin: user.isAdmin,
          createdAt: Timestamp.now()
        }
      }
    }
    return undefined;
  }

  function persistUserSession(session: IUserSession) {
    window.localStorage.setItem("userSession", JSON.stringify(session));
  }

  function revokeUserSession() {
    window.localStorage.removeItem("userSession");
  }



  return (
    <UserContext.Provider
      value={{
        userSession: userSession,
        createUserSession: createUserSession,
        isAuthenticated: isAuthenticated,
        revokeUserSession: revokeUserSession,
        setUserSession: setUserSession,
        persistUserSession: persistUserSession
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserSession(): UserContextProps {
  const context = useContext(UserContext);

  if (typeof context === "undefined") {
    throw new Error(
      "useUser should be used within the UserContext provider!"
    );
  }

  return context;
}

