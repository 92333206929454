/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { Dropdown } from "flowbite-react";
import { Flags } from "../..";
import { useTranslation } from "react-i18next";

interface Language {
  code: string;
  name: string;
  flag: any;
};

const availableLanguages: Array<Language> = [
  {
    code: 'en',
    name: "English",
    flag: (<Flags.UnitedKingdom />)
  },
  {
    code: 'no',
    name: "Norsk",
    flag: (<Flags.Norway />)
  }
];

function findActiveLanguage(language: string, availableLanguages: Array<Language>): Language {
    const defaultLang = {
      code: 'en',
      name: 'English',
      flag: <Flags.UnitedKingdom />
    };
    return availableLanguages.find(function(item) { return item.code === language; }) ?? defaultLang;
};

export const LanguageDropdown: FC = function () {

  const { i18n } = useTranslation();
  const activeLanguage = findActiveLanguage(i18n.language, availableLanguages);

  const changeLanguageHandler = (lang: string): void =>
  {
    i18n.changeLanguage(lang)
  };

  return (
    <Dropdown
      arrowIcon={false}
      inline
      label={
        <span className="inline-flex cursor-pointer justify-center rounded p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white">
          <span className="sr-only">Current language</span>
          <div className="h-5 w-5 rounded-full">
            {activeLanguage.flag}
          </div>
        </span>
      }
    >
      {availableLanguages.map(language => 
        <Dropdown.Item key={language.code} onClick={() => {changeLanguageHandler(language.code);}} >{language.flag}{language.name}</Dropdown.Item>
      )}
      
    </Dropdown>
  );
};
