import firebase from "firebase/compat/app";
import moment from "moment";

export class Timestamp {
  static now = () => {
    return firebase.firestore.Timestamp.fromDate(new Date());
  };
  static fromMoment = (m: moment.Moment): firebase.firestore.Timestamp =>
    firebase.firestore.Timestamp.fromDate(m.toDate());


  static toMoment = (timestamp: firebase.firestore.Timestamp): moment.Moment =>
    moment(
      new firebase.firestore.Timestamp(
        timestamp.seconds,
        timestamp.nanoseconds
      ).toDate()
    );
}
