/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useEffect, useState, useMemo } from "react";
import { PageLayout } from "../components";
import { useUserSession } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";
import { HiOutlinePlus, HiSwitchHorizontal, HiPencilAlt, HiTrash } from "react-icons/hi";
import { DefaultSkeleton, TableSkeleton, StandardTable, WorkspaceModal, DialogForm } from "../components";
import { ITableRow, IWorkspaceBase } from "../types";
import { useUserWorkspaces } from "../hooks";
import { useWorkspace } from "../context/WorkspaceContext";
import { createWorkspace, updateWorkspace, deleteWorkspace } from "../api";
import { WorkspaceAccess } from "../models";

const MyWorkspaces: FC = function () {
  const [isUpsertModalOpen, openUpsertodal] = useState(false); 
  const [isDeleteModalOpen, openDeleteModal] = useState(false); 
  const { userSession } = useUserSession();
  const { activeWorkspace, setActiveWorkspace } = useWorkspace();
  const [isLoading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const query = useUserWorkspaces(userSession?.user.email ?? "");
  const { t } = useTranslation(["common", "workspace"]);

  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query]
  );

  const queryData = useMemo(
    () => query.data ?? [],
    [query]
  );

  useEffect(() => {
    if(!isQueryLoading) {
      setLoading(false);
      setTableRows(queryData.map((workspace) => {
        return {
          id: workspace.workspaceId,
        isActive: workspace.workspaceId === activeWorkspace?.workspaceId,
        cells: [
          {
            id: "name",
            children: workspace.workspaceName,
            showOnSmallScreen: true
          },
        ],
        actions: [
          {
              id: "switch",
              icon: <HiSwitchHorizontal />,
              onActionClick: () => setActiveWorkspace(workspace)
          },
          {
            id: "edit",
            icon: <HiPencilAlt />,
            onActionClick: () => {
              setActiveWorkspace(workspace);
              openUpsertodal(true);
            }
          },
          {
              id: "delete",
              icon: <HiTrash />,
              color: "failure",
              onActionClick: () => {
                  setActiveWorkspace(workspace);
                  openDeleteModal(true);
              }
          }
        ]
        };
      }))
    }
  }, [isQueryLoading, queryData, activeWorkspace, isLoading, setActiveWorkspace]);


  if (userSession == null)
    return (
      <PageLayout>
        <DefaultSkeleton />:
      </PageLayout>
    );

  return (
    <PageLayout>
      <div className="relative pb-20">
        <h1 className="flex justify-between items-center align-center nowrap pb-8 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
          {t("workspace:my_workspaces")}
          <Button
            color="light"
            onClick={() => {
              setActiveWorkspace(undefined);
              openUpsertodal(true);
            }}
            type="submit"
          >
            <HiOutlinePlus className="mr-2 h-5 w-5" />
            {t("workspace:new_workspace")}
          </Button>
        </h1>
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow text:white">
                { isLoading ? (
                  <TableSkeleton />
                ) : (
                  <StandardTable 
                    header={[
                      {
                        id: "name",
                        children: t("workspace:workspaces.name"),
                        showOnSmallScreen: true
                      },
                    ]}
                    hasActions={true}
                    rows={tableRows}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <WorkspaceModal 
        data = {activeWorkspace ?? WorkspaceAccess.default()}
        isShowing = {isUpsertModalOpen} 
        onConfirm = {(formData: IWorkspaceBase) => {
          if (!activeWorkspace) {
            createWorkspace(userSession?.token.accessToken, formData);
          } else {
            updateWorkspace(userSession?.token.accessToken, activeWorkspace?.workspaceId ?? "", formData);
            if (activeWorkspace)
              activeWorkspace.workspaceName = formData.name;
            setActiveWorkspace(activeWorkspace);
          }
          openUpsertodal(false);
          setLoading(true); 
        }}
        onCancel = {() => openUpsertodal(false)}
      />

      <DialogForm
        title={t('workspace:delete_workspace')}
        show={isDeleteModalOpen}
        toggleModal={() => openDeleteModal(false)} 
        showHeader={false}
        buttonConfirmColor="failure"
        confirmButton={() => {
            deleteWorkspace(userSession?.token.accessToken, activeWorkspace?.workspaceId ?? "");
            openDeleteModal(false);
            setLoading(true); 
        }}
        buttonConfirmText={t("common:confirm")}
        buttonConfirmPrependIcon={<HiTrash className="mr-2 h-4 w-4" />}
        buttonCloseText={t("common:close")}
      />
    </PageLayout>
  );
};

export default MyWorkspaces;
