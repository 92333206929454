//https://stackoverflow.com/questions/41030361/how-to-update-react-context-from-inside-a-child-component

import type { PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useState } from "react";


interface DarkModeContextProps {
  isDarkMode: boolean;
  setDarkMode: (enable: boolean) => void;
}

const DarkModeContext = createContext<DarkModeContextProps>(undefined!);

export function DarkModeProvider({ children }: PropsWithChildren) {
  const [darkMode, setDarkMode] = useState(
    window.localStorage.getItem("darkMode") ?
        (window.localStorage.getItem("darkMode") === "true") :
        window.matchMedia('(prefers-color-scheme: dark)').matches
    );

  // Save latest state to localStorage
  useEffect(() => {
    window.localStorage.setItem("darkMode", darkMode.toString());
  }, [darkMode]);
  
  return (
    <DarkModeContext.Provider
      value={{
        isDarkMode: darkMode,
        setDarkMode: setDarkMode
      }}
    >
      {children}
    </DarkModeContext.Provider>
  );
}

export function useDarkMode(): DarkModeContextProps {
  const context = useContext(DarkModeContext);

  if (typeof context === "undefined") {
    throw new Error(
      "useDarkMode should be used within the DarkModeContext provider!"
    );
  }

  return context;
}

