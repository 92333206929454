import { Timestamp } from ".";
import { IUser } from ".";

export interface IWorkspaceBase {
    name: string | null;
}

export interface IWorkspace extends IWorkspaceBase {
    id: string;
    owner: string;
    createdBy: string | null;
    createdAt?: Timestamp;
}

export enum WorkspaceRole {
    Owner = "Owner",
    Administrator = "Administrator",
    ReadOnly = "ReadOnly"
}

export interface IWorkspaceAccessBase {
    email: string;
    role: WorkspaceRole;
}

export interface IWorkspaceAccess {
    workspaceId: string;
    workspaceName: string | null;
    email: string;
    role: WorkspaceRole;
    createdBy: string | null;
    createdAt?: Timestamp;
}

export interface IUserWithWorkspaceAccess {
    user: IUser;
    workspaces: IWorkspaceAccess[];
}