import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Logos } from "../Icons";

export const Brand: FC = function () {
    const { t } = useTranslation("common");

    return (
        <div className="flex flex-none flex-grow font-mono align-middle font-extrabold text-2xl text-sky-500">
            <Logos.MainLogo />
            {t('product_name')}
        </div>
    );
};


<span style={{fontSize:"24px", fontWeight:"bold", color:"#41b6e6", }}>Licon</span>