import type { FC, PropsWithRef } from "react";
import { Card } from "flowbite-react";
import { IUser } from "../../../types";

export const UserProfile: FC<PropsWithRef<IUser>> = function (user: IUser) {

    return (
        <Card>
            <div className="items-center sm:flex sm:space-x-4 xl:block xl:space-x-0 2xl:flex 2xl:space-x-4">
                <img
                    alt=""
                    src="../images/driver.svg"
                    className="mb-4 h-28 w-28 rounded-lg sm:mb-0 xl:mb-4 2xl:mb-0"
                />
                <div>
                    <h3 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
                        {user.displayName ?? user.email}
                    </h3>
                    <div className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        {user.email}
                    </div>
                </div>
            </div>
        </Card>
    );
  };