/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useMemo, useEffect } from "react";
import { Button } from "flowbite-react";
import { HiOutlinePlus, HiTrash, HiPencilAlt } from "react-icons/hi";
import { useWorkspaceUsers } from "../../hooks";
import { useUserSession } from "../../context/UserContext";
import { useWorkspace } from "../../context/WorkspaceContext";
import { useCache } from "../../context/CacheContext";
import { TableSkeleton, StandardTable, UserModal, DialogForm, PageLayout } from "../../components";
import { ITableRow, IWorkspaceAccessBase } from "../../types";
import { useTranslation } from "react-i18next";
import { createUser } from "../../api";
import { WorkspaceAccess } from "../../models";


export const WorkspaceUserPage: FC = function () {
  const [isUpsertModalOpen, openUpsertModal] = useState(false); 
  const [isDeleteModalOpen, openDeleteModal] = useState(false); 
  const [userData, setUserData] = useState(WorkspaceAccess.default());
  const [isLoading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const { activeWorkspace } = useWorkspace();
  const { updateCacheKey } = useCache();
  const { userSession } = useUserSession();
  const query = useWorkspaceUsers(activeWorkspace?.workspaceId ?? "");
  const { t } = useTranslation(["common", "user"]);

  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query]
  );

  const users = useMemo(
    () => query.data ?? [],
    [query]
  );

  useEffect(() => {
    if(!isQueryLoading) {
      setLoading(false);
      setTableRows(users.map((user) => {
        return {
          id: user.email,
          cells: [
            {
              id: "email",
              children: user.email,
              showOnSmallScreen: true
            },
            {
              id: "role",
              children: user.role
            },
          ],
          actions: [
            {
              id: "edit",
              icon: <HiPencilAlt />,
              onActionClick: () => {
                setUserData(user);
                openUpsertModal(true);
              }
            },
            {
                id: "delete",
                icon: <HiTrash />,
                color: "failure",
                onActionClick: () => {
                  setUserData(user);
                  openDeleteModal(true);
                }
            }
          ]
        };
      }))
    }
  }, [isQueryLoading, users]);

  return (
    <PageLayout>
      <div className="relative pb-20">
        <h1 className="flex justify-between items-center align-center nowrap pb-8 text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
          {t("user:my_users")}
          <Button
            color="light"
            onClick={() => {
              setUserData(WorkspaceAccess.default());
              openUpsertModal(true);
            }}
            type="submit"
          >
            <HiOutlinePlus className="mr-2 h-5 w-5" />
            {t("user:new_user")}
          </Button>
        </h1>
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow text:white">
                {isLoading ? (
                  <TableSkeleton />
                ) : (
                  <StandardTable 
                    header={[
                      {
                        id: "email",
                        children: t("user:email"),
                        showOnSmallScreen: true
                      },
                      {
                        id: "role",
                        children: t("user:role")
                      },
                    ]}
                    hasActions={true}
                    rows={tableRows}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <UserModal 
        data = {userData}
        isShowing = {isUpsertModalOpen} 
        onConfirm = {(formData: IWorkspaceAccessBase) => {
          if(WorkspaceAccess.isNew(userData))
            createUser(userSession?.token.accessToken ?? "", activeWorkspace?.workspaceId ?? "", formData);
          else
            WorkspaceAccess.updateWorkspaceUser(userData, formData);
          updateCacheKey();
          openUpsertModal(false);
          setLoading(true); 
        }}
        onCancel = {() => openUpsertModal(false)}
      />

      <DialogForm
        title={t('user:delete_user') + " " + userData.email +"?"}
        show={isDeleteModalOpen}
        toggleModal={() => openDeleteModal(false)} 
        showHeader={false}
        buttonConfirmColor="failure"
        confirmButton={() => {
            WorkspaceAccess.deleteWorkspaceUser(userData);
            openDeleteModal(false);
            updateCacheKey();
            setLoading(true); 
        }}
        buttonConfirmText={t("common:confirm")}
        buttonConfirmPrependIcon={<HiTrash className="mr-2 h-4 w-4" />}
        buttonCloseText={t("common:close")}
      />

    </PageLayout>

  );
};

