import firebase from "firebase/compat/app";
import { firestore } from "../firebaseConfig";
import { Timestamp, IQueryFilter, ParameterType, IParameter } from "../types";
import { query, WhereFilterOp } from "firebase/firestore";

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;

class Parameter implements IParameter {
  id: string;
  workspaceId: string;
  code: string;
  type: ParameterType;
  description?: string | null;
  createdAt?: Timestamp;

  static collectionName = "configuration_parameters";

  static converter = {
    toFirestore(parameter: Parameter) {
      return parameter.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as IParameter;
      return new Parameter({ ...data, id: snapshot.id });
    },
  };

  static default() {
    return new Parameter({
      id: "",
      workspaceId: "",
      code: "",
      type: ParameterType.All
    });
  }

  static isNew(data: IParameter): boolean {
    return data.id.length === 0;
  }

  static createId() {
    return firestore.collection(Parameter.collectionName).doc().id;
  }

  constructor({
    id,
    workspaceId,
    code,
    type,
    description,
    createdAt,
  }: IParameter) {
    this.id = id;
    this.workspaceId = workspaceId;
    this.code = code;
    this.type = type;
    this.description = description || null;
    this.createdAt = createdAt;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<IParameter>) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      workspaceId: this.workspaceId,
      code: this.code,
      type: this.type,
      description: this.description || null,
      createdAt: this.createdAt || null,
    };
  }

  static list = (workspaceId: string, filters?: Array<IQueryFilter>) => {

    var baseQuery = firestore
      .collection(Parameter.collectionName)
      .where("workspaceId", "==", workspaceId)

    if (filters) {
      filters.forEach((filter) => {
        baseQuery = baseQuery.where(filter.field, filter.operator as WhereFilterOp, filter.value);
      });
    }

    return query(
        baseQuery.withConverter(Parameter.converter),
    );
  };

  static create = async (workspaceId: string, data: Omit<IParameter, "id" | "createdAt">) => {
    const newParam = new Parameter({
      ...data,
      workspaceId: workspaceId,
      id: Parameter.createId(),
      createdAt: Timestamp.now(),
    });
    try {
      await firestore
        .collection(Parameter.collectionName)
        .doc(newParam.id)
        .withConverter(Parameter.converter)
        .set(newParam, { merge: true });
    } catch (e) {
      console.warn("Create configuration parameter failed with error: ", e);
      return false;
    }
    return true;
  };

  static update = async (
    parameter : Parameter,
    updates: Partial<IParameter>
  ) => {
    const updateType =
      (parameter.clone && parameter.clone()) || new Parameter({ ...parameter });

      updateType.setData({
      ...updates
    });

    await firestore
      .collection(Parameter.collectionName)
      .doc(parameter.id)
      .withConverter(Parameter.converter)
      .set(updateType, { merge: true });

    return updateType;
  };

  static delete = async (parameter : Parameter) => {
    return await firestore
        .collection(Parameter.collectionName)
        .doc(parameter.id)
        .delete();
  };

}

export default Parameter;
