import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { WorkspaceAccess } from "../models";
import { useCache } from "../context/CacheContext";

export const useUserWorkspaces = (email: string) => {
  const { cacheKey } = useCache();
  return useFirestoreQueryData(
    [WorkspaceAccess.collectionName, cacheKey, email],
    WorkspaceAccess.getUserWorkspaces(email),
    {
      subscribe: true,
    },
  );
};


