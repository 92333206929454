/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { PageLayout, UserProfile } from "../components";
import { useUserSession } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";
import { DefaultSkeleton } from "../components";

const ProfilePage: FC = function () {
    const { userSession } = useUserSession();
    const { t } = useTranslation(["common", "user"]);

    if (userSession == null)
        return (
            <PageLayout>
                <DefaultSkeleton />:              
            </PageLayout>
    );

    return (
        <PageLayout>
            <div className="relative pb-20">
                <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                    {t('user:profile.title')}
                </h1>

                <div className="grid grid-cols-1 gap-y-6 px-4 pt-6 dark:bg-gray-900 xl:grid-cols-3 xl:gap-4">
                    <div className="col-span-full">
                        <div className="grid grid-cols-1 gap-y-4">
                            <UserProfile {...userSession?.user} /> 
                            {userSession?.user.isAdmin ? 
                                <Button className="w-full lg:w-auto"  onClick={() => {navigator.clipboard.writeText(userSession?.token.accessToken ?? '')}}>{t("copy_token")}</Button>
                                : null
                            }
                        </div>
                    </div>
                </div>    
            </div>
        </PageLayout>
    );
  
};

export default ProfilePage;
