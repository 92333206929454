/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState } from "react";
import { TextInput } from "flowbite-react";
import { HiOutlinePlus } from "react-icons/hi";
import { StandardForm, Dialog } from "../..";
import { IWorkspaceAccess, IWorkspaceBase } from "../../../types";
import { useTranslation } from "react-i18next";
import { WorkspaceAccess } from "../../../models";

export interface WorkspaceModalProp {
    data: IWorkspaceAccess;
    isShowing: boolean;
    onConfirm: (data: IWorkspaceBase) => void;
    onCancel: () => void;
  }

export const WorkspaceForm: FC<WorkspaceModalProp> = function (props: WorkspaceModalProp) {

    const { t } = useTranslation(["common", "workspace"]);

    const [isNew] = useState(WorkspaceAccess.isNew(props.data));

    return (      
        <StandardForm
            showHeader={false}
            fields={[
                {
                    id: "name",
                    label: t("workspace:workspaces.name"),
                    input: (
                    <TextInput
                        id="name"
                        key="name"
                        name="name"
                        placeholder={t("workspace:workspaces.default_name")}
                        defaultValue={props.data?.workspaceName ?? ""}
                        type="text"
                        required={true}
                    />
                    ),
                }
            ]}
            onConfirm={props.onConfirm}
            onCancel={props.onCancel}
            buttonConfirmText={t("common:confirm")}
            buttonConfirmPrependIcon={ isNew ? <HiOutlinePlus className="mr-2 h-4 w-4" /> : null}
            buttonCloseText={t("common:close")}
        />

    );
};

export const WorkspaceModal: FC<WorkspaceModalProp> = function (props: WorkspaceModalProp) {

    const { t } = useTranslation(["common", "workspace"]);

    return (
        <Dialog
            title={ WorkspaceAccess.isNew(props.data) ? t("workspace:new_workspace") : t("workspace:edit_workspace")}
            content={<WorkspaceForm {...props} />}
            show={props.isShowing}
            toggleModal={props.onCancel}
            hideButtons={true}
        />
    );
};