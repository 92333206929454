import type { CustomFlowbiteTheme } from 'flowbite-react';

export const PrimaryTheme: CustomFlowbiteTheme = {
  button: {
    color: {
      primary: 'bg-red-500 hover:bg-red-600',
      mainGreen: 'bg-lgb-main-green hover:bg-lgb-main-green-lighten text-white'
    },
  },
  modal: {
    content: {
      inner: "relative rounded-lg bg-white shadow dark:bg-gray-800",
    },
    header: {
      base: "flex items-start justify-between rounded-t px-5 pt-5",
    },
  },
  textInput: {
    field: {
        input: {
            colors: {
              primary: 'text-red'
            }
        }
    }
  },
  sidebar: {
    root: {
      inner: "h-full overflow-y-auto overflow-x-hidden bg-gray-50 py-4 px-3 dark:bg-gray-800"
    }
  }
};