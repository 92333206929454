/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, PropsWithRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Label, TextInput } from "flowbite-react";
import { ValidationError } from '../../../types';
import { Brand } from '../../atoms/Brand';

interface LoginFormProps {
    onClicklogin: (email: string, password:string ) => any;
    validation: ValidationError;
    isProcessing?: boolean | false;
}

const SignInPage: FC<PropsWithRef<LoginFormProps>> = function ({
    onClicklogin, validation, isProcessing
  }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { t } = useTranslation(['common', 'user']);

    function handleLogin(event: any) {
        event.preventDefault();
        return onClicklogin(email, password);
    }

    return (
        <div className="flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12">
            <div className="my-6 flex items-center gap-x-1 lg:my-0">
                <Brand />
            </div>
            <Card
                horizontal
                imgSrc=""
                imgAlt=""
                className="w-full md:max-w-[600px] [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 md:[&>*]:w-full md:[&>*]:p-16 lg:[&>img]:block"
            >
                <h1 className="mb-3 text-2xl font-bold dark:text-white md:text-3xl">
                    {t("user:login.title")} {t("common:product_name")}
                </h1>
                <form onSubmit={handleLogin}>
                    <div className="mb-4 flex flex-col gap-y-3">
                        <Label htmlFor="email">{t("user:login.email.label")}</Label>
                        <TextInput
                            id="email"
                            name="email"
                            placeholder={t("user:login.email.placeholder")}
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            color={validation.errorFields && validation.errorFields.includes("email") ? "failure" : "grey"}
                        />
                    </div>
                    <div className="mb-4 flex flex-col gap-y-1">
                        <Label htmlFor="password">{t('user:login.password.label')}</Label>
                        <TextInput
                            id="password"
                            name="password"
                            placeholder={t('user:login.password.placeholder')}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            color={validation.errorFields && validation.errorFields.includes("password") ? "failure" : "grey"}
                        />
                    </div>
                    {validation.isError && 
                        <div className="mb-4 flex flex-col gap-y-1">
                            <p className="mt-1 text-sm text-red-600 dark:text-red-500">{validation.errorMessage}</p>
                        </div>
                    }
                    <div className="mb-4 flex items-center w-full">
                        <Button 
                            type="submit" 
                            className="w-full lg:w-full lg:max-w-full" 
                            isProcessing={isProcessing}
                        >
                            {t('user:login.login')}
                        </Button>
                    </div>
                </form>
                <div className="mb-6 flex items-left justify-between">
                    <p className="text-sm text-gray-500 dark:text-gray-300">
                        <a
                        href="/reset_password"
                        className="w-1/2 text-right text-sm text-primary-600 dark:text-primary-300"
                        >
                            {t('user:login.lost_password')}
                        </a>
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-300">
                        {t('user:login.not_registered')}&nbsp;
                        <a href="/signup" className="text-primary-600 dark:text-primary-300">
                            {t('user:login.signup')}
                        </a>
                    </p>
                </div>
            </Card>
        </div>
    );
};

export default SignInPage;
