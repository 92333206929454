import axios from "axios";
import { IWorkspaceBase, IWorkspaceAccessBase } from "../types";

export const signup = async (token: string)  => {
    const response = await apiPost(token, '/api/signup/');
    return response.data;
};

export const createUser = async (
    token: string,
    workspaceId: string,
    user: IWorkspaceAccessBase,
  ) => {
    const response = await apiPost(token, `/api/workspaces/${workspaceId}/invite`, user);
    return response.data;
  };

export const getMyUser = async (token: string) => {
    const response = await apiGet(token, '/api/users/me');
    return response.data;
};

export const createWorkspace = async (token: string, workspace: IWorkspaceBase)  => {
    const response = await apiPost(token, '/api/workspaces/', workspace);
    return response.data;
};

export const updateWorkspace = async (token: string, workspaceId: string, workspace: IWorkspaceBase)  => {
    const response = await apiPut(token, `/api/workspaces/${workspaceId}`, workspace);
    return response.data;
};

export const deleteWorkspace = async (token: string, workspaceId: string)  => {
    const response = await apiDelete(token, `/api/workspaces/${workspaceId}`);
    return response.data;
};

const apiPost = async (token: string, url: string, data: any = {}) => {
    console.log(JSON.stringify(data));
    const request = axios.post(
        `${baseUrl}${url}`, 
        data,
        { headers: { Authorization: `Bearer ${token}` } }
    );
    return request
        .then((response: any) => response.data)
        .catch((error: any) => console.log(error));
};

const apiPut = async (token: string, url: string, data: any = {}) => {
    console.log(JSON.stringify(data));
    const request = axios.put(
        `${baseUrl}${url}`, 
        data,
        { headers: { Authorization: `Bearer ${token}` } }
    );
    return request
        .then((response: any) => response.data)
        .catch((error: any) => console.log(error));
};

const apiGet = async (token: string, url: string) => {
    const request = axios.get(
        `${baseUrl}${url}`, 
        { headers: { Authorization: `Bearer ${token}` } }
    );
    return request
        .then((response: any) => response.data)
        .catch((error: any) => console.log(error));
};

const apiDelete = async (token: string, url: string) => {
    const request = axios.delete(
        `${baseUrl}${url}`, 
        { headers: { Authorization: `Bearer ${token}` } }
    );
    return request
        .then((response: any) => response.data)
        .catch((error: any) => console.log(error));
};

const baseUrl : string = process.env.REACT_APP_CLOUD_API_URL || "http://localhost:8080";

