import type { FC } from "react";

export const NewMessageIcon: FC = function () {
  return (
    <svg
      className="h-3 w-3 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
      <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
    </svg>
  );
};

export const NewFollowIcon: FC = function () {
  return (
    <svg
      className="h-3 w-3 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"></path>
    </svg>
  );
};

export const NewLoveIcon: FC = function () {
  return (
    <svg
      className="h-3 w-3 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const NewMentionIcon: FC = function () {
  return (
    <svg
      className="h-3 w-3 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const NewVideoIcon: FC = function () {
  return (
    <svg
      className="h-3 w-3 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
    </svg>
  );
};


export const DashboardIcon: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1688_24612)">
        <path
          d="M14.7086 0H9.96891C9.25473 0 8.67578 0.578952 8.67578 1.29313V6.03281C8.67578 6.74699 9.25473 7.32594 9.96891 7.32594H14.7086C15.4228 7.32594 16.0017 6.74699 16.0017 6.03281V1.29313C16.0017 0.578952 15.4228 0 14.7086 0Z"
          fill="#7214FF"
        />
        <path
          d="M6.03281 0H1.29313C0.578952 0 0 0.578952 0 1.29313V6.03281C0 6.74699 0.578952 7.32594 1.29313 7.32594H6.03281C6.74699 7.32594 7.32594 6.74699 7.32594 6.03281V1.29313C7.32594 0.578952 6.74699 0 6.03281 0Z"
          fill="#7214FF"
        />
        <path
          d="M14.7086 8.67578H9.96891C9.25473 8.67578 8.67578 9.25473 8.67578 9.96891V14.7086C8.67578 15.4228 9.25473 16.0017 9.96891 16.0017H14.7086C15.4228 16.0017 16.0017 15.4228 16.0017 14.7086V9.96891C16.0017 9.25473 15.4228 8.67578 14.7086 8.67578Z"
          fill="#7214FF"
        />
        <path
          d="M6.03281 8.67578H1.29313C0.578952 8.67578 0 9.25473 0 9.96891V14.7086C0 15.4228 0.578952 16.0017 1.29313 16.0017H6.03281C6.74699 16.0017 7.32594 15.4228 7.32594 14.7086V9.96891C7.32594 9.25473 6.74699 8.67578 6.03281 8.67578Z"
          fill="#7214FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1688_24612">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AdministrationIcon: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1688_24642)">
        <path
          d="M9.93437 15.3032C9.88125 15.2719 9.825 15.2407 9.77188 15.2094L9.44375 15.35C8.8 15.625 8.05312 15.375 7.70312 14.7688L7.14062 13.7938C6.79063 13.1875 6.95 12.4157 7.50938 11.9938L7.79375 11.7813C7.79375 11.75 7.79375 11.7188 7.79375 11.6875C7.79375 11.6563 7.79375 11.625 7.79375 11.5938L7.50938 11.3813C6.95 10.9594 6.79375 10.1875 7.14062 9.58129L7.70312 8.60629C7.87188 8.31566 8.1375 8.09691 8.45312 7.99066C7.675 7.63441 6.82813 7.45004 5.97188 7.45316C2.675 7.45004 0 10.125 0 13.4219V15.1532C0 15.6219 0.378125 16 0.846875 16H10.0625C10.0188 15.8907 9.99063 15.775 9.975 15.6563L9.93437 15.3032Z"
          fill="#41E88D"
        />
        <path
          d="M5.97344 6.55625C7.7839 6.55625 9.25156 5.08858 9.25156 3.27813C9.25156 1.46767 7.7839 0 5.97344 0C4.16298 0 2.69531 1.46767 2.69531 3.27813C2.69531 5.08858 4.16298 6.55625 5.97344 6.55625Z"
          fill="#41E88D"
        />
        <path
          d="M15.798 12.6945L15.1261 12.1914C15.1792 11.8602 15.1792 11.5195 15.1261 11.1883L15.798 10.6852C16.0042 10.532 16.0605 10.2477 15.9324 10.0227L15.3699 9.04766C15.2417 8.82578 14.9667 8.73203 14.7292 8.83516L13.9574 9.16328C13.6949 8.95078 13.4011 8.78203 13.0886 8.66016L12.9886 7.82578C12.9574 7.56953 12.7417 7.37891 12.4824 7.37891H11.3542C11.098 7.37891 10.8792 7.57266 10.848 7.82578L10.748 8.66016C10.4324 8.78203 10.1417 8.95078 9.87923 9.16328L9.10736 8.83516C8.86986 8.73516 8.59486 8.82578 8.46673 9.04766L7.90423 10.0227C7.77611 10.2445 7.83236 10.5289 8.03861 10.6852L8.71048 11.1883C8.65736 11.5195 8.65736 11.8602 8.71048 12.1914L8.03861 12.6945C7.83236 12.8508 7.77611 13.1352 7.90423 13.357L8.46673 14.332C8.59486 14.5539 8.86986 14.6477 9.10736 14.5477L9.87923 14.2164C10.1417 14.4289 10.4355 14.5977 10.748 14.7195L10.848 15.5539C10.8792 15.8102 11.0949 16.0008 11.3542 16.0008H12.4824C12.7386 16.0008 12.9574 15.807 12.9886 15.5539L13.0886 14.7195C13.4011 14.5977 13.6949 14.4289 13.9574 14.2164L14.7292 14.5445C14.9667 14.6445 15.2417 14.5539 15.3699 14.3289L15.9324 13.3539C16.0605 13.1352 16.0042 12.8508 15.798 12.6945ZM11.9167 12.907C11.2449 12.907 10.698 12.3602 10.698 11.6883C10.698 11.0164 11.2449 10.4695 11.9167 10.4695C12.5886 10.4695 13.1355 11.0164 13.1355 11.6883C13.1355 12.3602 12.5886 12.907 11.9167 12.907Z"
          fill="#41E88D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1688_24642">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};


export const WorkSpaceIcon: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1855_27091)">
        <path
          d="M13.0196 4.78305H7.24706L6.09882 3.41834C6.0602 3.37191 6.00231 3.34587 5.94196 3.34775H1.28471C0.573647 3.35293 0 3.93081 0 4.64187V13.0356C0.000862745 13.7491 0.579059 14.3273 1.29255 14.3281H13.0196C13.7331 14.3273 14.3113 13.7491 14.3122 13.0356V6.07559C14.3113 5.3621 13.7331 4.78391 13.0196 4.78305Z"
          fill="#0AAF87"
        />
        <path
          d="M14.7165 3.10726H8.94234L7.79411 1.74256C7.75548 1.69613 7.6976 1.67009 7.63724 1.67197H2.98156C2.33638 1.67303 1.79081 2.14966 1.70312 2.78883H5.9494C6.17207 2.78828 6.38348 2.88656 6.52665 3.05707L7.50705 4.22256H13.0208C14.0406 4.22428 14.8669 5.0506 14.8686 6.0704V12.6398C15.5152 12.5591 16.0007 12.0099 16.0012 11.3582V4.39824C15.9995 3.6884 15.4263 3.11244 14.7165 3.10726Z"
          fill="#0AAF87"
        />
      </g>
      <defs>
        <clipPath id="clip0_1855_27091">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const UserProfileIcon: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1859_26710)">
        <path
          d="M8 0C5.67378 0 3.78125 1.89253 3.78125 4.21875C3.78125 6.54497 5.67378 8.4375 8 8.4375C10.3262 8.4375 12.2188 6.54497 12.2188 4.21875C12.2188 1.89253 10.3262 0 8 0Z"
          fill="#6B7280"
        />
        <path
          d="M13.2489 11.1936C12.0939 10.0208 10.5628 9.375 8.9375 9.375H7.0625C5.43725 9.375 3.90606 10.0208 2.75106 11.1936C1.60172 12.3606 0.96875 13.901 0.96875 15.5312C0.96875 15.7901 1.17862 16 1.4375 16H14.5625C14.8214 16 15.0312 15.7901 15.0312 15.5312C15.0312 13.901 14.3983 12.3606 13.2489 11.1936Z"
          fill="#6B7280"
        />
      </g>
      <defs>
        <clipPath id="clip0_1859_26710">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};