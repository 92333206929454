import { v4 as uuidv4 } from 'uuid';
import firebase from "firebase/compat/app";
import { firestore } from "../firebaseConfig";
import { Timestamp, IWorkspace } from "../types";
import { query, doc } from "firebase/firestore";

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;

class Workspace implements IWorkspace {
  id: string;
  name: string | null;
  owner: string;
  createdBy: string | null;
  createdAt?: Timestamp;


  static collectionName = "workspaces";

  static converter = {
    toFirestore(workspace: Workspace) {
      return workspace.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as IWorkspace;
      return new Workspace({ ...data, id: snapshot.id });
    }
  };

  static createId() {
    return firestore
      .collection(Workspace.collectionName)
      .doc().id;
  }

  constructor({
    id,
    name,
    owner,
    createdBy,
    createdAt
  }: IWorkspace) {
    this.id = id;
    this.name = name || null;
    this.owner = owner;
    this.createdBy = createdBy || null;
    this.createdAt = createdAt;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<IWorkspace>) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      name: this.name || "",
      owner: this.owner,
      createdBy: this.createdBy || "",
      createdAt: this.createdAt || null
    };
  }

  static defaultWorkspace = (): IWorkspace => {
    return {
      id: "",
      name: "",
      owner: "",
      createdBy: "",
      createdAt: undefined
    };
  }

  static get = (id: string) => {
    return doc(
      firestore.collection(Workspace.collectionName)
        .withConverter(Workspace.converter),
      id);
  };

  static list = () => {
    return query(
      firestore.collection(Workspace.collectionName)
        .withConverter(Workspace.converter)
      );
  };


  static create = async (
    data: Omit<
      IWorkspace,
      "id" | "createdAt"
    >
  ) => {
      const newWorkspace = new Workspace({
        ...data,
        id: uuidv4.toString(),
        createdAt: Timestamp.now()
      });

      await firestore
        .collection(Workspace.collectionName)
        .doc(newWorkspace.id)
        .withConverter(Workspace.converter)
        .set(newWorkspace, { merge: true });

      return newWorkspace;
    
  };
}


export default Workspace