import type { FC } from "react";

export const MainLogo: FC = function () {
  return (
    <svg 
        className="mr-3 h-6 sm:h-8 fill-sky-500"
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="206.315 115 137.4 136.2"
        fill="currentColor"
    >
        <path d="M206.31536,183.09891c0,-37.61002 30.48909,-68.09891 68.09927,-68.09891c15.86087,0 30.45536,5.42224 42.03098,14.5143c-8.81537,-4.9087 -18.96773,-7.70455 -29.77312,-7.70455c-33.84916,0 -61.28937,27.44035 -61.28937,61.28915c0,33.84914 27.44021,61.2893 61.28937,61.2893c10.80539,0 20.95775,-2.79622 29.77312,-7.70455c-11.57562,9.09213 -26.17011,14.51452 -42.03098,14.51452c-37.61018,0 -68.09927,-30.48913 -68.09927,-68.09927zM343.68464,183.09891c0,30.08813 -24.39116,54.47939 -54.47902,54.47939c-12.68868,0 -24.36426,-4.33786 -33.62458,-11.61159c7.05211,3.92666 15.17395,6.16371 23.81832,6.16371c27.07932,0 49.03151,-21.95215 49.03151,-49.03151c0,-27.07917 -21.95219,-49.03114 -49.03151,-49.03114c-8.64437,0 -16.76621,2.23697 -23.81832,6.16312c9.26032,-7.27343 20.93589,-11.61136 33.62458,-11.61136c30.08786,0 54.47902,24.39109 54.47902,54.47939zM266.17389,152.1448v46.84961h32.71106v15.05883h-40.24044c-4.09938,0 -7.52938,-3.43006 -7.52938,-7.52938v-54.37906z" />
    </svg>
  );
};