import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { User } from "../models";

const useUsers = () => {
  return useFirestoreQueryData(
    [User.collectionName], 
    User.list(), 
    {
      subscribe: false,
    }
  );
};

export default useUsers;
