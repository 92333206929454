/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC, PropsWithRef } from "react";
import {
  DarkThemeToggle,
  Navbar,
} from "flowbite-react";
import {
  HiMenuAlt1,
  HiX,
} from "react-icons/hi";
import { useSidebarContext } from "../../../context/SidebarContext";
import isSmallScreen from "../../../helpers/is-small-screen";
import { UserDropdown } from "../../molecules/UserDropdown"
import { AppDrawerDropdown } from "../../molecules/AppDrawerDropdown"
import { NotificationBellDropdown } from "../../molecules/NotificationBellDropdown"
import { IUser } from "../../../types";
import { Brand } from "../../atoms/Brand";
import { useDarkMode } from "../../../context/DarkModeContext";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { useTranslation } from "react-i18next";

export const NavigationBar : FC<PropsWithRef<IUser>> = function (user) {
  const { isOpenOnSmallScreens, isPageWithSidebar, setOpenOnSmallScreens } =useSidebarContext();
  const { isDarkMode, setDarkMode } = useDarkMode(); 
  const { t } = useTranslation("navigation");
  const { activeWorkspace } = useWorkspace();

  return (
    <Navbar fluid className="fixed z-30 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <div className="w-full p-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            {isPageWithSidebar && (
              <button
                onClick={() => setOpenOnSmallScreens(!isOpenOnSmallScreens)}
                className="mr-3 cursor-pointer rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:inline"
              >
                <span className="sr-only">{t("sidebar.toggle")}</span>
                {isOpenOnSmallScreens && isSmallScreen() ? (
                  <HiX className="h-6 w-6" />
                ) : (
                  <HiMenuAlt1 className="h-6 w-6" />
                )}
              </button>
            )}
            <Navbar.Brand href="/">
                <Brand />
            </Navbar.Brand>
          </div>
          <div className="flex items-center lg:gap-3">
            <div className="flex items-center">
              {!isSmallScreen() ? <div className="p-2 text-gray-600 dark:text-gray-400">
                  {activeWorkspace?.workspaceName}
                </div> : null
              }
              <NotificationBellDropdown />
              <div className="hidden lg:block">
                <AppDrawerDropdown />
              </div>
              <DarkThemeToggle onClick={() => setDarkMode(!isDarkMode)}/>
              <div className="ml-2">
                <UserDropdown 
                  {...user}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  ); 
};
