/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState } from "react";
import { TextInput, Select } from "flowbite-react";
import { HiOutlinePlus } from "react-icons/hi";
import { StandardForm, Dialog } from "../..";
import { IWorkspaceAccessBase, IWorkspaceAccess} from "../../../types";
import { useTranslation } from "react-i18next";
import { WorkspaceAccess } from "../../../models";

export interface UserModalProp {
    data: IWorkspaceAccess;
    isShowing: boolean;
    onConfirm: (data: IWorkspaceAccessBase) => void;
    onCancel: () => void;
  }

export const UserForm: FC<UserModalProp> = function (props: UserModalProp) {

    const { t } = useTranslation(["common", "user"]);

    const [isNew] = useState(WorkspaceAccess.isNew(props.data));

    return (      
        <StandardForm
            showHeader={false}
            fields={[
                {
                    id: "email",
                    label: t("common:email"),
                    input: (
                      <TextInput
                        id="email"
                        key="email"
                        name="email"
                        placeholder={t("common:email")}
                        defaultValue={props.data.email}
                        type="text"
                        required={true}
                      />
                    ),
                  },
                  {
                    id: "role",
                    label: t("user:role"),
                    input: (
                      <Select 
                        defaultValue={props.data.role}
                        id="role"
                        key="role"
                        name="role"
                      >
                        <option key="ReadOnly">ReadOnly</option>
                        <option key="Administrator">Administrator</option>
                        <option key="Owner">Owner</option>
                      </Select>
                    ),
                  }
            ]}
            onConfirm={props.onConfirm}
            onCancel={props.onCancel}
            buttonConfirmText={t("common:confirm")}
            buttonConfirmPrependIcon={ isNew ? <HiOutlinePlus className="mr-2 h-4 w-4" /> : null}
            buttonCloseText={t("common:close")}
        />

    );
};

export const UserModal: FC<UserModalProp> = function (props: UserModalProp) {

    const { t } = useTranslation(["common", "user"]);

    return (
        <Dialog
            title={ WorkspaceAccess.isNew(props.data) ? t("user:new_user") : t("user:edit_user")}
            content={<UserForm {...props} />}
            show={props.isShowing}
            toggleModal={props.onCancel}
            hideButtons={true}
        />
    );
};