import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { WorkspaceAccess } from "../models";
import { useCache } from "../context/CacheContext";

export const useWorkspaceUsers = (workspaceId: string) => {
  const { cacheKey } = useCache();
  return useFirestoreQueryData(
    [WorkspaceAccess.collectionName, cacheKey, workspaceId],
    WorkspaceAccess.getWorkspaceUser(workspaceId),
    {
      subscribe: false,
    },
  );
};

