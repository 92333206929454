//https://stackoverflow.com/questions/41030361/how-to-update-react-context-from-inside-a-child-component

import type { PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useState } from "react";

interface CacheContextProps {
  cacheKey: string | undefined;
  setCacheKey: (cacheKey: string) => void;
  updateCacheKey: () => void;
}

const CacheContext = createContext<CacheContextProps>(undefined!);

export function CacheProvider({ children }: PropsWithChildren) {
  const [cacheKey, setCacheKey] = useState(
    window.localStorage.getItem("workspaceCacheKey") ?? Date.now().toString(),
  );

  // Save latest state to localStorage
  useEffect(() => {
    window.localStorage.setItem("workspaceCacheKey", cacheKey);
  }, [cacheKey]);

  function updateCacheKey(): void {
    setCacheKey(Date.now().toString());
  }

  return (
    <CacheContext.Provider
      value={{
        cacheKey: cacheKey,
        setCacheKey: setCacheKey,
        updateCacheKey: updateCacheKey,
      }}
    >
      {children}
    </CacheContext.Provider>
  );
}

export function useCache(): CacheContextProps {
  const context = useContext(CacheContext);

  if (typeof context === "undefined") {
    throw new Error(
      "useCache should be used within the CacheContext provider!",
    );
  }

  return context;
}
